$(function (where, html) {
    const navExpand = $(".mobile-navbar-expand");
    const backLinkTemplateMaker = {
        setText: (backLinkText) => {
            return `<li class="mobile-navbar-item">
                        <a class="mobile-navbar-link mobile-navbar-back-link" href="javascript:;">
                        <i class="icon icon--left"></i>
                            ${backLinkText ? backLinkText : "Vissza"}
                        </a>
                    </li>`;
        }
    };

    navExpand.each((index, item) => {
        let mobileNavbarExpandContent = $(item).find('.mobile-navbar-expand-content')[0];
        let mobileNavbarLink = $(item).find('.mobile-navbar-link')[0];
        let mobileNavbarBackLinkText = $(item).data('backlinktext');

        mobileNavbarExpandContent.insertAdjacentHTML('afterbegin', backLinkTemplateMaker.setText(mobileNavbarBackLinkText));
        $(mobileNavbarLink).on('click', () => item.classList.add('active'))

        let mobileNavbarBackLink = $(item).find('.mobile-navbar-back-link')[0];
        $(mobileNavbarBackLink).on('click', () => item.classList.remove("active"))
    })

    $("#toggler").on('click', function () {
        let icon = $(this).find('.icon')[0];
        $(icon).toggleClass("icon--close");
        $(icon).toggleClass("icon--menu");
        document.body.classList.toggle('nav-is-toggled');
    })
});
