import {fireGTM} from "@/custom/fireGTM";

$(function (where, html) {
    fireGTM("visit", "Visit");

    $('.js-submit-delete-user-form').on('click', function () {
        if (confirm('Valóban törölni szeretné a profilját?')) {
            $("#delete-user-form").submit();
        }
    });

    $('.js-submit-logout-form').on('click', function () {
        $("#logout-form").submit();
    });

    $('.js-submit-order-brochure-form').on('click', function () {
        $("#order-brochure-form").submit();
    });

    $('.js-submit-order-summary-form').on('click', function () {
        $("#order-summary-form").submit();
    });

    $('.js-modal-opener').on('click', function () {
        let modalId = $(this).data('target');
        $("#lightbox-backdrop").fadeIn();
        $(modalId).fadeIn();
    });

    $('.js-modal-close,#lightbox-backdrop').on('click', function () {
        let videoPlayerModal = $('#video-play-modal')[0];
        let mobileVideoPlayerModal = $('#mobile-video-play-modal')[0];

        if (!$(videoPlayerModal).is(':visible')) {
            $("#lightbox-backdrop").fadeOut();
            $('.modal').fadeOut();
        }

        if ($(mobileVideoPlayerModal).is(':visible')) {
            $("#lightbox-backdrop").fadeOut();
            $('.modal').fadeOut();
        }
    });

    $('.js-video-player-modal-opener').on('click', function () {
        let videoType = $(this).data('type');
        let posterURL = $(this).data('poster');
        let videoURL = $(this).data('video');
        let videoModalContent = ".video-player-modal__content";
        let videoModalId = $(this).data('target');
        let videoName = $(this).data('videoname');
        let videoPayerModalTemplate = '';
        if(videoType==='extended') {
            videoPayerModalTemplate = `
            <div class="extended">
                <video controls="" controlslist="nodownload" poster="${posterURL}" id="tv-video" class="js-gtm-video" data-video-label="${videoName}">
                    <source src="${videoURL}" type="video/mp4">
                </video>
                <div class="content-panel">
                    ` + $(this).data('content'); + `
                </div>
            </div>
        `;
        } else {
            videoPayerModalTemplate = `
            <video controls="" controlslist="nodownload" poster="${posterURL}" id="tv-video" class="js-gtm-video" data-video-label="${videoName}">
                <source src="${videoURL}" type="video/mp4">
            </video>
        `;
        }

        $("#lightbox-backdrop").fadeIn();
        $(videoModalContent).empty();
        $(videoModalContent).append(videoPayerModalTemplate);

        $(".js-gtm-video").each((index, video) => {

            $(video)[0].addEventListener("play", function (e) {
                const videoName = $(video).attr("data-video-label");
                const category = `video start - ${videoName}`;
                fireGTM("video", category);
            }, false);

            $(video)[0].addEventListener("ended", function (e) {
                const videoName = $(video).attr("data-video-label");
                const category = `video finished - ${videoName}`;
                fireGTM("video", category);
            }, false);
        })

        $(videoModalId).fadeIn();
    });

    $('.js-video-player-modal-close,#lightbox-backdrop').on('click', function () {
        $('.video-player-modal').fadeOut();
        $("#lightbox-backdrop").fadeOut();
        $('video').each((index, video) => {
            video.pause();
            video.currentTime = 0;
        });
    });

    $('.js-toggle-dropdown-icon').on('click', function () {
        let findArrow = $(this).find('.icon')[0];
        $(findArrow).toggleClass('icon--down');
        $(findArrow).toggleClass('icon--up');

        $('.nav-link .icon').each((index, icon) => {
            if (icon !== findArrow) {
                $(icon).removeClass('icon--up');
                $(icon).addClass('icon--down');
            }
        });
    });

    $(window).on('click', function () {
        $('.nav-link .icon').each((index, icon) => {
            $(icon).removeClass('icon--up');
            $(icon).addClass('icon--down');
        });
    });

    $('.js-close-tab').on('click', function () {
        window.top.close();
    });


});
$(function () {
    "use strict"; // Start of use strict

    // Toggle the side navigation
    $("#sidebarToggle, #sidebarToggleTop").on('click', function (e) {
        $("body").toggleClass("sidebar-toggled");
        $(".sidebar").toggleClass("toggled");
        if ($(".sidebar").hasClass("toggled")) {
            $('.sidebar .collapse').collapse('hide');
        }
        ;
    });

    // Close any open menu accordions when window is resized below 768px
    $(window).resize(function () {
        if ($(window).width() < 768) {
            $('.sidebar .collapse').collapse('hide');
        }
        ;

        // Toggle the side navigation when window is resized below 480px
        if ($(window).width() < 480 && !$(".sidebar").hasClass("toggled")) {
            $("body").addClass("sidebar-toggled");
            $(".sidebar").addClass("toggled");
            $('.sidebar .collapse').collapse('hide');
        }
        ;
    });

    // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
    $('body.fixed-nav .sidebar').on('mousewheel DOMMouseScroll wheel', function (e) {
        if ($(window).width() > 768) {
            var e0 = e.originalEvent,
                delta = e0.wheelDelta || -e0.detail;
            this.scrollTop += (delta < 0 ? 1 : -1) * 30;
            e.preventDefault();
        }
    });

    // Scroll to top button appear
    $(document).on('scroll', function () {
        var scrollDistance = $(this).scrollTop();
        if (scrollDistance > 100) {
            $('.scroll-to-top').fadeIn();
        } else {
            $('.scroll-to-top').fadeOut();
        }
    });

    // Smooth scrolling using jQuery easing
    $(document).on('click', 'a.scroll-to-top', function (e) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: ($($anchor.attr('href')).offset().top)
        }, 1000, 'easeInOutExpo');
        e.preventDefault();
    });
})

$(document).ready(function () {

    $('#dataTable').DataTable({
        "bInfo": false,
        "language": {
            "lengthMenu": "Listázott sorok száma _MENU_ ",
            "search": "Keresés:",
        }
    });
})

$(document).ready(function () {

    function loaded (){

        if(window.innerWidth > 992){
            const footerTop = document.querySelector('.stand__right').getClientRects()[0].bottom;
            $('.stand__footer').css('top',Math.floor(footerTop) + 'px');
        }
    }

   let standRightImage = $('.stand__right > img')[0];

    if (standRightImage.complete) {
        loaded();
    } else {
        standRightImage.addEventListener('load', loaded)
    }

    $(window).resize(loaded);
})
