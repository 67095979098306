function fireGTM(eventName, category) {
    let dataObject = {
        'event': eventName,
        'gaEventCategory': category,
        'gaEventAction': category,
        'gaEventLabel': category
    };

    if (typeof dataLayer != 'undefined') {
        window.dataLayer.push(dataObject);
    }
}

export {fireGTM}
